import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Stack,
    Slider,
    IconButton,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
} from '@mui/material';
import { Favorite, Share } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { pink } from '@mui/material/colors';

const Bird = ({ name, slug, text, playing, volume, favorite, onPlayClick, onVolumeChange, onFavoriteClick, onShareClick }) => {

    const [loading, setLoading] = useState(true);

    const img = new Image();
    img.src = `/images/${ slug }-800w.jpg`;
    img.onload = function () {
        setLoading(false)
    };


    return (
        <Card variant="outlined"
            sx={ {
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            } }
            component="article"
        >
            <CardMedia
                component="img"
                height={ 250 }
                src={ loading ? `/images/sx/${ slug }-sx.jpg` : `/images/${ slug }-800w.jpg` }
                alt={ name }
                sx={ {
                    objectPosition: 'top',
                    filter: loading ? 'blur(10px)' : 'none',
                    transition: 'filter 1.5s ease',
                } }
            />
            <CardContent>
                <Stack
                    spacing={ 2 }
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                    >
                        { name }
                    </Typography>
                    <Button
                        onClick={ onPlayClick }
                        variant="contained"
                        size="small"
                    >
                        { playing ? 'Pause' : 'Play' }
                    </Button>
                </Stack>
                <Slider
                    aria-label="Volume"
                    value={ volume }
                    min={ 0 }
                    max={ 1 }
                    step={ 0.01 }
                    disabled={ !playing }
                    onChange={ (e) => {
                        onVolumeChange(e.target.value)
                    } }
                />
                <Typography
                    variant="body2"
                    color="text.secondary"
                >
                    { text.length ? text.split(' ').slice(0, 20).join(' ') + '...' : ''}
                </Typography>
            </CardContent>
            <CardActions
                disableSpacing
                sx={ {
                    marginTop: 'auto',
                    justifyContent: 'space-between',
                } }
            >
                <div>
                    <IconButton aria-label="add to favorites" onClick={onFavoriteClick}>
                        <Favorite sx={{ color: favorite ? pink[600] : 'inherit' }} />
                    </IconButton>
                    <IconButton aria-label="share" onClick={onShareClick}>
                        <Share />
                    </IconButton>
                </div>
                <Button
                    component={ Link }
                    to={ `/${ slug }/` }
                    size="small"
                    title={`read about ${name}`}
                >Read More</Button>
            </CardActions>
        </Card>
    );
};

Bird.propTypes = {
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    text: PropTypes.string,
};

export default Bird;
