import React, { useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Player } from "@lottiefiles/react-lottie-player";
import { MusicOff, MusicNote } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function Header({ playing, isPlayingSomething, onPlayClick }) {
    const player = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        isPlayingSomething ? player.current.play() : player.current.stop();
    }, [isPlayingSomething]);
    return (
        <Box
            sx={ {
                overflow: 'hidden',
            } }
            component="header"
        >
            <AppBar
                position="static"
                color="primary"
                component="section"
            >
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <nav>
                        <Stack component="a" direction="row" alignItems="center" onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                            <img
                                src="/logo.png"
                                alt="logo"
                                width={ 32 }
                            />
                            <Typography
                                variant="h6"
                                component="span"
                                sx={ { ml: 2 } }
                            >
                                Bird Sound
                            </Typography>
                        </Stack>
                    </nav>
                    <Button
                        color="inherit"
                        sx={ { zIndex: 2 } }
                        onClick={ onPlayClick }
                        startIcon={playing ? <MusicNote /> : <MusicOff />}
                    >Forest Sounds</Button>
                </Toolbar>
            </AppBar>
            <Box
                sx={ {
                    position: 'relative',
                } }
                component="section"
            >
                <Box
                    sx={ {
                        display: {
                            xs: 'none',
                            sm: 'block'
                        },
                        fontSize: {
                            sm: '.7em',
                            md: '.9em',
                            lg: '1em',
                        },
                        width: '30em',
                        position: 'absolute',
                        top: '50%',
                        right: '-2.5em',
                        transform: 'translateY(-50%)',
                    } }
                >
                    <Player
                        ref={player}
                        loop
                        speed={ .7 }
                        src="/animations/23100-happy-bird.json"
                    />
                </Box>
                <Typography
                    component="h1"
                    color="text.primary"
                    sx={ {
                        textAlign: {
                            xs: 'center',
                            sm: 'left',
                        },
                        fontSize: '2em',
                        flexGrow: 1,
                        padding: {
                            xs: '2rem 1rem',
                            sm: '2rem',
                        }
                    } }
                >
                    Listen to the
                    <Typography
                        component="div"
                        sx={ {
                            fontSize: {
                                xs: '2em',
                                md: '3em',
                            },
                            flexGrow: 1,
                            paddingLeft: '.2em',
                        } }
                    >
                        <Typography
                            component="span"
                            color="primary"
                            fontSize='1em'
                        >
                            &nbsp;Bird Sounds
                        </Typography>
                    </Typography>
                </Typography>
            </Box>
        </Box>
    );
}

export default Header;
