import { useState, useEffect } from "react";

function useFavorite() {

    const [favorites, setFavorites] = useState(() => {
        let favorites = localStorage.getItem('favorites');
        if (favorites) {
            favorites = favorites.split(',');
        } else {
            favorites = [];
        }
        return favorites;
    });

    useEffect(() => {
        localStorage.setItem('favorites', favorites.join(','));
    }, [favorites]);


    return [favorites,
        id => {
            id = id.toString();
            if (favorites.includes(id)) {
                setFavorites(favorites.filter((favorite) => favorite!==id));
            } else {
                setFavorites([...favorites, id]);
            }
        }];
}

export default useFavorite;
