import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import BIRDS from '../birds';
import {
    useParams,
    Navigate,
    useNavigate,
} from "react-router-dom";
import {
    Card,
    CardContent,
    Typography,
    Button, Slider, CardMedia, Stack, IconButton, CardActions, Snackbar,
} from '@mui/material';
import { Favorite, ArrowBack, Share } from "@mui/icons-material";
import { pink } from "@mui/material/colors";
import useFavorite from "../hooks/useFavorite";
import useShare from "../hooks/useShare";


function Bird({ playing, volume, onPlayClick, onVolumeChange }) {
    const { slug } = useParams();
    const navigate = useNavigate();

    const bird = BIRDS[slug];
    if (!bird) {
        return <Navigate to="/" replace />;
    }
    const [isCopied, shareIt] = useShare();
    const [favorites, setFavorites] = useFavorite();
    const favorite = favorites.includes(bird.order.toString());

    useEffect(() => {
        localStorage.setItem('favorites', favorites.join(','));
    }, [favorites]);

    return (
        <>
            <Helmet>
                <title>Listen to { bird.name } sound | Bird Sound</title>
                <meta
                    name="description"
                    content={ `On this page, you can continuously listen to the sound of ${ bird.name } bird and also get information about it. Use this sound to work or meditate` }
                />
                <link rel="canonical" href={ `https://bird-sound.com/${ slug }/` } />
                <meta
                    property="og:title"
                    content={ bird.name }
                />
                <meta
                    property="og:type"
                    content="article"
                />
                <meta
                    property="og:url"
                    content={ `https://bird-sound.com/${ slug }` }
                />
                <meta
                    property="og:image"
                    content={ `https://bird-sound.com/images/${ slug }-800w.jpg` }
                />
            </Helmet>
            <Card sx={ { boxShadow: 0, maxWidth: 900, mx: 'auto' } }>
                <Snackbar
                    open={ isCopied }
                    message="Bird's link copied to clipboard"
                    anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
                />
                <CardMedia
                    component="img"
                    image={ `/images/${ slug }-800w.jpg` }
                    alt={ name }
                    sx={ {
                        objectPosition: 'top',
                    } }
                />

                <CardContent>
                    <Stack
                        spacing={ 2 }
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Typography
                            gutterBottom
                            variant="h3"
                            component="h1"
                        >
                            { bird.name }
                        </Typography>
                        <Button
                            onClick={ () => onPlayClick(slug) }
                            variant="contained"

                        >
                            { playing[slug] ? 'Pause' : 'Play' }
                        </Button>
                    </Stack>
                    <Slider
                        aria-label="Volume"
                        value={ volume[slug] ?? 0.5 }
                        min={ 0 }
                        max={ 1 }
                        step={ 0.01 }
                        disabled={ !playing[slug] }
                        onChange={ (e) => {
                            onVolumeChange(slug, e.target.value)
                        } }
                    />
                    <Typography component="div">
                        {
                            bird.text.split('\n').map((line, index) => {
                                return <p key={ index }>{ line }</p>;
                            })
                        }
                    </Typography>
                </CardContent>
                <CardActions
                    disableSpacing
                    sx={ {
                        marginTop: 'auto',
                        justifyContent: 'space-between',
                    } }
                >
                    <div>
                        <IconButton
                            aria-label="add to favorites"
                            onClick={ () => setFavorites(bird.order) }
                        >
                            <Favorite sx={ { color: favorite ? pink[600] : 'inherit' } } />
                        </IconButton>
                        <IconButton
                            aria-label="share"
                            onClick={ () => shareIt(bird.name, bird.slug) }
                        >
                            <Share />
                        </IconButton>
                    </div>
                    <Button
                        size="small"
                        startIcon={ <ArrowBack /> }
                        onClick={ () => navigate(-1) }
                    >Back</Button>
                </CardActions>
            </Card>
            <script type="application/ld+json">
                { JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "AudioObject",
                    "contentUrl": `${window.location.origin}/sounds/${ slug }.mp3`,
                    "description": `Sound of ${ bird.name } bird`,
                    "duration": bird.duration,
                    "encodingFormat": "audio/mpeg",
                    "name": `${ slug }.mp3`,
                }) }
            </script>
        </>
    );
}

export default Bird;
