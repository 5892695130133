import React from 'react';
import {
    Outlet,
} from "react-router-dom";
import Header from "../components/Header";
import Box from "@mui/material/Box";

function Home({ playing, isPlayingSomething, onPlayClick }) {
    return (
        <div>
            <Header playing={ playing } isPlayingSomething={ isPlayingSomething } onPlayClick={ onPlayClick } />
            <Box p={ 3 }>
                <Outlet />
            </Box>
        </div>
    );
}

export default Home;
