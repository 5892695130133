import React, { useState, useEffect } from 'react';
import Bird from './Bird';
import BIRDS from '../birds';
import Pagination from '@mui/material/Pagination';
import { useSearchParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import {
    Box,
    TextField,
    Grid,
    Stack,
    Container,
    Card,
    CardContent,
    Snackbar,
    Button,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { Search } from "@mui/icons-material";
import useFavorite from "../hooks/useFavorite";
import useShare from "../hooks/useShare";

const birdsSchema = Object.values(BIRDS).map(bird => {
    return {
        "@type":"ListItem",
        "position":bird.order,
        "url":`https://bird-sound.com/${ bird.slug }/`,
    }
});


function Birds({ playing, volume, onPlayClick, onVolumeChange, onPauseAll }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isCopied, shareIt] = useShare();

    const [favoritesOnlyChecked, setFavoritesOnlyChecked] = useState(false);

    const initialFilteredBirds = Object.values(BIRDS).filter((bird) => {
        return bird.name.toLowerCase().includes(searchParams.get("search") || '');
    });
    const [filteredBirds, setFilteredBirds] = useState(initialFilteredBirds);

    const [favorites, setFavorites] = useFavorite();

    const perPage = 24;
    const [page, setPage] = useState(parseInt(searchParams.get("page") || '1', 10));

    const initialTotalPage = Math.ceil(filteredBirds.length / perPage)
    const [totalPages, setTotalPages] = useState(initialTotalPage);

    const handlePageChange = (e, page) => {
        if (page===1) {
            searchParams.delete("page");
        } else {
            searchParams.set("page", page);
        }
        setSearchParams({
            ...Object.fromEntries(searchParams),
        });
        setPage(page);
    }

    useEffect(() => {
        setFilteredBirds(Object.values(BIRDS).filter((bird) => {
            const searchKey = (searchParams.get("search") || '').toLowerCase();
            const containsSearch = bird.name.toLowerCase().includes(searchKey);
            const favoriteChecked = favoritesOnlyChecked ? favorites.includes(bird.order.toString()) : true;
            return containsSearch && favoriteChecked;
        }));
        setPage(1);
    }, [searchParams.get('search'), favoritesOnlyChecked]);




    useEffect(() => {
        if (favoritesOnlyChecked && page > 1) {
            searchParams.delete("page");
            setSearchParams({
                ...Object.fromEntries(searchParams),
            });
            setPage(1);
        }
    }, [favoritesOnlyChecked]);

    useEffect(() => {
        setTotalPages(Math.ceil(filteredBirds.length / perPage));
    }, [filteredBirds]);

    function handleSearchChange(search) {
        if (search) {
            setSearchParams({ search });
        } else {
            setSearchParams({});
        }
    }

    const canonicalHref =  'https://bird-sound.com/' + (page > 1 ? `?page=${page}` : '')
    const titlePageSuffix =  page > 1 ? ` - page ${page}` : '';
    const descPageSuffix =  page > 1 ? ` page ${page}` : '';
    const prevPage = 'https://bird-sound.com/' + (page > 2 ? `?page=${page - 1}` : '')
    const nextPage = `https://bird-sound.com/?page=${page + 1}`;

    return (
        <>
            <Card sx={ { boxShadow: 0 } }>
            <Helmet>
                <title>{`Bird Sound - Listen and combine the sounds of birds${titlePageSuffix}`}</title>
                <meta
                    name="description"
                    content={`On this site, you can listen to the sound of any bird you want continuously and even combine it with the sound of other birds. Try it.${descPageSuffix}`}
                />
                <link rel="canonical" href={canonicalHref} />
                {page > 1 && <link rel="prev" href={prevPage} /> }
                { page < totalPages && <link rel="next" href={nextPage} /> }
            </Helmet>
            <Snackbar
                open={ isCopied }
                message="The bird link copied to the clipboard"
                anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
            />
            <CardContent component='main'>
                <Container maxWidth="xl">
                    <Grid
                        container
                        spacing={ 2 }
                    >
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Box sx={ { display: 'flex', alignItems: 'center', flexWrap: 'wrap' } }>
                                <Box sx={ { display: 'flex', alignItems: 'center', flexGrow: .2, mr: 'auto' } }>
                                    <Search sx={ { color: 'action.active', mr: 1, my: 0.5 } } />
                                    <TextField
                                        label="Call a bird"
                                        fullWidth
                                        variant="standard"
                                        value={ searchParams.get("search") || "" }
                                        onChange={ (e) => {
                                            handleSearchChange(e.target.value)
                                        } }
                                        sx={ { mb: 3 } }
                                    />
                                </Box>
                                <FormControlLabel
                                    control={ <Checkbox
                                        checked={ favoritesOnlyChecked }
                                        onChange={ () => setFavoritesOnlyChecked(!favoritesOnlyChecked) }
                                    /> }
                                    label="Favorites only"
                                    sx={ { color: 'action.active' } }
                                />
                                <Button
                                    onClick={ onPauseAll }
                                    variant="outlined"
                                    size="small"
                                    disabled={ !Object.values(playing).some(i => i) }
                                >
                                    Pause All
                                </Button>
                            </Box>
                        </Grid>
                        { filteredBirds
                            .sort((b, a) => a.order - b.order)
                            .slice((page - 1) * perPage, page * perPage)
                            .map(bird =>
                                <Grid
                                    item
                                    key={ bird.slug }
                                    xs={ 12 }
                                    sm={ 6 }
                                    lg={ 4 }
                                    xl={ 3 }
                                >
                                    <Bird
                                        name={ bird.name }
                                        slug={ bird.slug }
                                        text={ bird.text }
                                        playing={ playing[bird.slug] ?? false }
                                        volume={ volume[bird.slug] ?? 0.5 }
                                        favorite={ favorites.includes(bird.order.toString()) }
                                        onPlayClick={ () => onPlayClick(bird.slug) }
                                        onVolumeChange={ (v) => onVolumeChange(bird.slug, v) }
                                        onFavoriteClick={ () => setFavorites(bird.order) }
                                        onShareClick={ () => shareIt(bird.name, bird.slug) }
                                    />
                                </Grid>
                            ) }
                    </Grid>

                    <Stack
                        alignItems="center"
                        mt={ 4 }
                    >
                        <Pagination
                            count={ totalPages }
                            page={ page }
                            onChange={ handlePageChange }
                        />
                    </Stack>
                </Container>
            </CardContent>
        </Card>
            <script type="application/ld+json">
                { JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "ItemList",
                    "numberOfItems": birdsSchema.length,
                    "itemListElement": birdsSchema,
                }) }
            </script>
        </>
    );
}

export default Birds;
