import React, { useRef, useState } from 'react';
import Home from './pages/Home';
import Bird from './pages/Bird';
import Birds from './components/Birds';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";

function App() {
    const [playing, setPlaying] = useState({});
    const [volume, setVolume] = useState({});
    const audios = useRef({});
    const forestAudio = useRef({});
    const [playingForest, setPlayingForest] = useState(false);
    const isPlayingSomething = playingForest || Object.values(playing).some((p) => p);

    const onForestPlayClick = () => {
        if (!forestAudio.current.src) {
            const audio = new Audio();
            audio.src = `/sounds/forest.ogg`;
            audio.volume = 0.5;
            audio.loop = true;
            forestAudio.current = audio;
        }
        forestAudio.current.paused ? forestAudio.current.play() : forestAudio.current.pause();
        setPlayingForest(!playingForest);
    }

    const makeAudio = (slug) => {
        const audio = new Audio();
        audio.src = `/sounds/${ slug }.mp3`;
        audio.volume = 0.5;
        audio.loop = true;
        return audio;
    }

    const onPlayClick = (slug) => {
        if (!audios.current[slug]) {
            audios.current[slug] = makeAudio(slug);
        }
        audios.current[slug].paused ? audios.current[slug].play() : audios.current[slug].pause();
        setPlaying({ ...playing, [slug]: !audios.current[slug].paused });
    }

    const onVolumeChange = (slug, value) => {
        audios.current[slug].volume = value;
        setVolume({ ...volume, [slug]: value });
    }

    const onPauseAll = () => {
        Object.values(audios.current).forEach((audio) => {
            audio.pause();
        });
        setPlaying({});
    }


    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route
                    path="/"
                    element={ <Home playing={playingForest} isPlayingSomething={isPlayingSomething} onPlayClick={onForestPlayClick}/> }
                >
                    <Route
                        index
                        element={ <Birds
                            playing={ playing }
                            volume={ volume }
                            onPlayClick={ onPlayClick }
                            onVolumeChange={ onVolumeChange }
                            onPauseAll={ onPauseAll }
                        /> }
                    />
                    <Route
                        path="/:slug"
                        element={
                            <Bird
                                playing={ playing }
                                volume={ volume }
                                onPlayClick={ onPlayClick }
                                onVolumeChange={ onVolumeChange }
                            />
                        }
                    />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
