import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App.js'
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        background: {
            main: "#f00"
        },
        primary: {
            main: '#009688',
        },
        secondary: {
            main: '#9c4b3a',
        },
    },
});

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(
        <ThemeProvider theme={ theme }>
            <App />
        </ThemeProvider>
        , rootElement);
} else {
    render(
        <ThemeProvider theme={ theme }>
            <App />
        </ThemeProvider>
        , rootElement);
}
