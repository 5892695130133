import { useState } from "react";

function useShare() {

    const [copyAlert, setCopyAlert] = useState(false);

    const onShareClick = async (name, slug) => {
        const url = `${ window.location.origin }/${ slug }`;
        const sharableData = {
            title: name,
            url,
        };
        if (window.navigator && window.navigator.canShare && navigator.canShare(sharableData)) {
            await window.navigator.share(sharableData);
        } else {
            navigator.clipboard.writeText(url)
                .then(() => {
                    setCopyAlert(true);
                    setTimeout(() => {
                        setCopyAlert(false);
                    }, 3000);
                });
        }
    }

    return [copyAlert, onShareClick];
}

export default useShare;
